export default {
    protocol: 'http',
    url: {
        api: 'http://localhost:8090',
        image: 'https://ga-index-public.s3.us-west-2.amazonaws.com'
    },
    key: {
        api: 'hello'
    },
    gtag: {
        id: 'G-P06DM95KYD',
        appName: 'GA Index'
    },
    disqus: {
        shortname: 'gatcg'
    },

    enable: {
        disqus: false,
        gtag: false
    }
}