export default {
    protocol: 'https',
    url: {
        api: 'https://api-dev.gatcg.com',
        image: 'https://cdn.gatcg.com/images'
    },
    gtag: {
        id: 'G-P06DM95KYD',
        appName: 'GA Index'
    },
    disqus: {
        shortname: 'gatcg'
    },

    enable: {
        disqus: false,
        gtag: false
    }
}