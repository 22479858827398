<script>
// CustomContent for rendering html content with Vue component. Must have "runtimeCompiler: true" in "vue.config.js"
export default {
    name: "DynamicContent",
    props: {
        html: {
            type: String, 
            required: true
        }
    },
    data() {
        return {
            text: `
                <router-link to="/cards">
                    <b-button>
                        to Cards
                    </b-button>
                </router-link>
            `
        }
    },
    computed: {
        transformed() {
            const transformedText = `
                <div>
                    ${this.html.replaceAll('<a', '<router-link').replaceAll('href=', 'to=').replaceAll('</a>', '</router-link>')}
                </div>`

            return { template: transformedText }
        }
    },
    mounted() {
        
    }
}
</script>


<template>
    <div v-bind:is="transformed">
    </div>
</template>