class TextService {

    capitalizeFirstChar(word) {
        return word ? word.toLowerCase().replace(/\b\w/g, l => l.toUpperCase()) : ''
    }

    toMonthDateYear(dateString) {
        //expect 2021-08-20 10:49:05.132 +0700 or just 2021-08-20
        const date = new Date(dateString)

        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    }

    parseLabel (word) {
        if (word === 'collector_number') {
            return this.capitalizeFirstChar(word.replace(/_/gm, ' '))
        }

        return this.capitalizeFirstChar(
            word.replace(/_/gm, ' ') // replace underscore with space
            .split(/\s/).reverse().join(' ') // reverse label with multiple words 'cost_memory' => 'Memory Cost'
        )
    }

    parseType(array, separator = ' ') {
        if (!array || !array.length) return ''
        return array.map(type => this.capitalizeFirstChar(type)).join(separator)
    }

    getCardSubtitle({types, classes, subtypes}) {
        // all params must be array from the card attribute
        // {{ type }} — {{ classification }} — {{ subtype }}
        let classification = this.parseType(classes, '/')
        const subtypesWithoutClasses = this.parseType(
            subtypes.filter(
                value => classes ? !classes.includes(value) : true // in case classes is not defined
            )
        );
        return `${this.parseType(types)} — ${classification} ${subtypesWithoutClasses}`
    }

    getRarityString(level, short = false) {
        // const rarity = this.$searchOptions.rarity.sort((a, b) => parseInt(a.value) - parseInt(b.value));
        const rarity = this.$searchOptions.rarity
        
        // valid levels: 1-9
        if (level < 1 || level > 9) {
            return `Invalid Rarity Level ${level}`
        }
        
        let rarityString;
        if (!short) {
            rarityString = rarity.map(r => r.text)
        } else {
            rarityString = rarity.map(r => r.text.split(" ").map(w => w[0]).join(""))
        }

        return rarityString[level-1]
    }

    getOperatorString(operator) {
        const operators = ['>', '<', '=', '≈', '∞']
        const text = ['More than', 'Less than', 'Exactly', 'About', 'Unlimited']

        return text[operators.indexOf(operator)]
    }
    
    getFullLanguageText(lang){
        const short = ['EN']
        const long = ['English']

        return long[short.indexOf(lang)]
    }

    getOrderText (order) {
        if (order === 'ASC') return 'Ascending'
        if (order === 'DESC') return 'Descending'
    }

    getSearchComments (data) {
        // data must include query, stats, option
        // query is the query object that is being sent to the API
        // option is for sorting translated query attributes

        const comments = []
        const attributeTranslations = [
            {
                attribute: 'name',
                title: 'the name includes',
                type: 'string'
            },
            {
                attribute: 'effect',
                title: 'the effect includes',
                type: 'string'
            },
            {
                attribute: 'prefix',
                title: 'they belong to the set',
                type: data.option.set
            },
            {
                attribute: 'collector_number',
                title: 'the collector number is',
                type: 'string'
            },
            {
                attribute: 'element',
                title: 'the element is',
                type: data.option.element
            },
            {
                attribute: 'type',
                title: 'the type is',
                type: data.option.type
            },
            {
                attribute: 'subtype',
                title: 'the subtype is',
                type: data.option.subtype
            },
            {
                attribute: 'class',
                title: 'the class is',
                type: data.option.class
            },
            {
                attribute: 'rarity',
                title: 'the rarity is',
                type: data.option.rarity
            },
            {
                attribute: 'flavor',
                title: 'the flavor text includes',
                type: 'string'
            },
            {
                attribute: 'illustrator',
                title: 'the illustrator\'s name includes',
                type: 'string'
            }
        ]

        for (const [key, value] of Object.entries(data.query)) {
            const translation = attributeTranslations.filter(attr => attr.attribute === key)[0];
            if (!translation || !value) continue;

            let translatedValue = value;
            if (translation.type === 'string') {
                translatedValue = `“${value}”`
            }
            // if the type is array and the value is array
            if (Array.isArray(translation.type) && Array.isArray(value) && value.length) {
                if (value.length > 1) {
                    const logicalOperator = data.query[`${key}_logic`] ? data.query[`${key}_logic`].toLowerCase() : 'or'
                    
                    translatedValue = value.map(v => `“${ translation.type.filter(t => t.value === v)[0].text }”`).join(', ')
                    //replace the last comma with 'and' or 'or'
                    translatedValue = `${translatedValue.substring(0, translatedValue.lastIndexOf(', '))} ${logicalOperator} ${translatedValue.substring(translatedValue.lastIndexOf(', ') + 2, translatedValue.length)}`

                    if(logicalOperator === 'or') translatedValue = `either ${translatedValue}`
                } else if (value.length === 1) {
                    translatedValue = `“${ translation.type.filter(t => t.value === value[0])[0].text }”`
                }
            }

            // if the type is array, use the array values to translate
            if (Array.isArray(translation.type) && !Array.isArray(value)) {
                translatedValue = translation.type.filter(item => item.value === value)
                translatedValue = translatedValue.length ? translatedValue[0].text : value
                translatedValue = `“${translatedValue}”`
            }
            
            if (translatedValue.length) {
                comments.push({
                    key,
                    value,
                    text: `${translation.title} ${translatedValue}`
                })
            }
        }

        if (data.query && data.query.stats) {
            data.query.stats.split(';').reduce((params, param) => {
                // let [key, operator, value] = param.split(/(=|>|<|>=|<|<=)(\d+)/);
                let [key, operator, value] = param.split(/(=|>|<|>=|<|<=)((-?)\d+)/);
                let comment = null
                
                // add a special comment
                if (
                    (key === 'cost_reserve' || key === 'cost_memory') &&
                    (value == -1)
                ) {
                    comment = `“${this.parseLabel(key)}” = X`
                }
                
                let operatorSymbol = operator
                if (operator === '>=') operatorSymbol = '≥'
                if (operator === '<=') operatorSymbol = '≤'
                comments.push({
                    name: key,
                    value,
                    text: comment ? comment : `“${this.parseLabel(key)}” ${operatorSymbol} ${value}`
                })
            }, {})
        }

        if (data.query && data.query.legality_format) {
            const title = data.query.legality_limit > 0 ? `limited to ${data.query.legality_limit}` : 'banned'
            
            comments.push({
                name: 'legality',
                value: 'test',
                text: `they are ${title} in ${this.capitalizeFirstChar(data.query.legality_format)} game format `
            })
        }

        return comments;
    }
    convertToURLParams (query) {
        let queryString = ''
        const logicParams = ['type_logic', 'subtype_logic', 'class_logic']
        
        for (const [key, value] of Object.entries(query)) {

            // do not include logic "OR" in logic params
            if (logicParams.indexOf(key) > -1 &&
                value === 'OR') {
                continue;
            }

            if(value && value.length && Array.isArray(value)) {
                value.forEach((v) => {
                    queryString += `${key}=${v}&`
                })
                continue
            }
            
            if(
                (value && value.length) ||
                typeof value === 'number'
                ) {
                queryString += `${key}=${value}&`
            }
        }
        
        // need to always remove the last &
        queryString = queryString.slice(0, queryString.length - 1)
        return queryString
    }
    filterQueryObject (query) {
        // remove unnecessary attributes of query before navigating to a route
        const logicParams = ['type_logic', 'subtype_logic', 'class_logic']

        const filteredQuery = {}
        for (const [key, value] of Object.entries(query)) {
            if (logicParams.indexOf(key) > -1 &&
                value === 'OR') {
                continue;
            }

            if (
                (value && value.length) ||
                (typeof value === 'number')
            ) {
                filteredQuery[key] = value
            }
        }

        return filteredQuery
    }

    // convert an arrray of stat into a string query supported by stats attribute in card search API
    // input object
    // [
    //     {
    //         name: '',
    //         operator: '',
    //         value: null
    //     },
    //     {
    //         name: '',
    //         operator: '',
    //         value: null
    //     }
    // ]
    // output: level>2;life<10;cost_memory>=1
    getStatQuery (stats) {
        let queryString = ''
        stats.forEach((stat, index) => {

            // handle special operator
            // =-1 -> variable
            if (stat.operator === '=-1') {
                stat.operator = "="
                stat.value = -1
            }

            if (
                !stat.name ||
                !stat.operator ||
                !stat.value && stat.value !== 0
            ) {
                return;
            }
            queryString += `${stat.name}${stat.operator}${stat.value}`
            if (index < stats.length -1) {
                queryString += ';'
            }
        })

        return queryString
    }
}

export default new TextService();