import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
	},
	// {
	//   path: '/about',
	//   name: 'About',
	//   // route level code-splitting
	//   // this generates a separate chunk (about.[hash].js) for this route
	//   // which is lazy-loaded when the route is visited.
	//   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
	// },
	{
		path: '/card/:slug',
		name: 'Card',
		component: () => import('../views/Card.vue'),
	},
	{
		path: '/edition/:editionSlug',
		name: 'Edition',
		component: () => import('../views/Card.vue')
	},
	{
		path: '/cards',
		name: 'SearchResult',
		component: () => import('../views/SearchResult.vue'),
		meta: {
			title: "Cards",
		}
	},
	{
		path: '/advanced-search',
		name: 'AdvancedSearch',
		component: () => import('../views/AdvancedSearch.vue'),
		meta: {
			title: "Advanced Search",
		}
	},
	{
		path: '/news',
		name: 'News',
		component: () => import('../views/News.vue'),
		meta: {
			title: "News",
		}
	},
	{
		path: '/pnp',
		name: 'Printer',
		component: () => import('../views/Printer.vue'),
		meta: {
			title: "PnP",
		}
	},
	{
		path: '/api',
		name: 'API',
		component: () => import('../views/API.vue'),
		meta: {
			title: "API",
		}
	}
]

const router = new VueRouter({
	mode: 'history',
	routes
})

router.afterEach(to => {
	const siteName = 'Index — Grand Archive Card Database'
	if (to.meta.title) {
		document.title = `${to.meta.title} : ${siteName}`;
	} else {
		document.title = 'Index — Grand Archive Card Database';
	}
});

export default router
