<script>
export default {
	name: "CostIcon",
	props: {
		cost: {
            type: Number,
        },
        type: {
            type: String
        },
        small: Boolean,
	},
	computed: {
        fCost() {
            if (this.cost === -1) return "X"

            return this.cost
        },
	},
};
</script>

<template>
	<div
        class="cost__icon"
        :class="[`cost__icon--${type}`,{'cost__icon--small' : small}]"
        :title="`${type} cost: ${cost}`"
    >
    <span class="cost__icon__text">{{ fCost }}</span>
	</div>
</template>


<style lang="scss" scoped>
.cost__icon {
    display: inline-block;
    background-position: center;
    background-size: 40px;
    background-repeat: no-repeat;
    height: 40px;
    width: 40px;

    color: #fefceb;
    text-align: center;
    font-size: 1.1rem;
    line-height: 2.4rem;
    font-weight: bold;
    text-shadow:
        -1px 0 rgba(0,0,0, 0.7),
        0 1px rgba(0,0,0, 0.7),
        1px 0 rgba(0,0,0, 0.7),
        0 -1px rgba(0,0,0, 0.7),
        1px 3px 3px rgba(0,0,0,0.75)
        ;
	&--memory {
        background-image: url(/images/icons/memory_cost.png);
    }
    &--reserve {
        background-image: url(/images/icons/reserve_cost.png);
    }

    &--small {
        height: 30px;
        width: 30px;
        background-size: 30px;
        font-size: 1rem;
        line-height: 1.9rem;
    }
}
</style>
