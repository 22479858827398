<script>
import {debounce} from 'lodash';
import DataService from "@/services/data";

import { SlideYUpTransition } from 'vue2-transitions'


export default {
	name: "Search",
	components: {
        SlideYUpTransition
	},
	props: {
        // automatically set seletectedCard to be the first item of suggestedCards after the data is available
        // useful when the user presses enter and go the first result immediately
        // not useful when the user presses enter and expect and full search result in catalog page
        autoSelectFirstItem: Boolean,
	},
	data() {
		return {
			query: null,
            suggestedCards: [],
            selectedIndex: -1, // for up/down key
            showSuggestion: false,
            noCardFound: false,
            suggestionHover: false,
		};
	},
    methods: {
         search: debounce(function() {
            if (this.query && this.query.length > 1)
            // console.log('calling searchAutocomplete')
            DataService.searchAutocomplete(this.query)
				.then((response) => {
					// console.log(response);
                    if (response.data.length) {
                        this.showSuggestion = true
                        this.noCardFound = false
                        this.selectedIndex = -1
                        // this.suggestedCards = response.data.length <= 5 ? response.data : response.data.slice(0, 5); // limit to 5 results max
                        this.suggestedCards = response.data;

                        if(this.autoSelectFirstItem) {
                            // this.selectedCard = this.suggestedCards[0]
                            this.selectedIndex = 0
                        }
                    }

                    if (response.data.length) {
                        this.noCardFound = true
                    }
				})
				.catch((e) => {
					console.log(e);
				});
        }, 200),
        goToSelectedCard () {

            if (!this.selectedCard) {
                this.navigateToResult()
                return
            }
            this.navigateToCard(this.selectedCard.slug)
        },
        navigateToResult () {
            this.$router.push({ name: 'SearchResult',  query: { name: this.query }  })
            this.clearInput()
        },
        navigateToCard (slug) {
            // do not go to the same Card page with the same card
            if (
                this.$router.history.current.name &&
                this.$router.history.current.name === 'Card' &&
                this.$router.history.current.params &&
                this.$router.history.current.params.slug === slug
                )
            {
                console.warn(`navigateToCard: not navigating to route "Card" with slug ${slug} because the current page is the same page.`)
                return;
            }

            this.$router.push({ name: 'Card', params: { slug } })
            this.clearInput()
        },
        clearInput () {
            this.$nextTick().then(() => {
                this.query = ''
                // this.selectedCard = null;
                this.selectedIndex = -1;
                this.showSuggestion = false;
            })
        },
        onBlur () {
            if (!this.suggestionHover) this.showSuggestion = false
        },
        onFocus () {
            if (this.suggestedCards.length) this.showSuggestion = true
        },
        onUpArrowKey () {
            if (this.suggestedCards.length === 0) {
                return
            }

            if (this.selectedIndex === 0) {
                this.selectedIndex = this.suggestedCards.length - 1
                this.scrollToItem(this.selectedIndex)
                return
            }

            this.selectedIndex = this.selectedIndex - 1
            this.scrollToItem(this.selectedIndex)
        },
        onDownArrowKey () {
            if (this.suggestedCards.length === 0) {
                return
            }

            if (this.selectedIndex === this.suggestedCards.length -1) {
                this.selectedIndex = 0
                this.scrollToItem(this.selectedIndex)
                return
            }

            this.selectedIndex = this.selectedIndex + 1
            this.scrollToItem(this.selectedIndex)
        },
        scrollToItem (index) {

            if (this.suggestedCards.length > 5 && index > 4) {
                document.getElementById('suggestion').scrollTop = 40 * (index)
                return
            }

            if (this.suggestedCards.length > 5 && index <= 4) {
                document.getElementById('suggestion').scrollTop = 0
            }
        }
    },
    computed: {
        selectedCard () {
            if (this.selectedIndex < 0) {
                return null
            }
            return this.suggestedCards[this.selectedIndex]
        }
    }
};
</script>

<template>
	<div 
        class="card-search text-left"
    >  
        <b-form-input
            class="card-search__input"
            :class="{'card-search__input--has-suggestion' : showSuggestion }"
            v-model.trim="query"
            type="text"
            placeholder="Search Cards"
            @input="search"
            @keyup.enter="goToSelectedCard"
            @keyup.backspace="showSuggestion = false"
            @blur.native="onBlur"
            @focus.native="onFocus"
            @keyup.up="onUpArrowKey"
            @keydown.down="onDownArrowKey"
        >
        </b-form-input>
        <slide-y-up-transition appear :duration=300 mode="out-in">
            <div
                v-if="showSuggestion"
                class="card-search__suggestion"
                @mouseenter="suggestionHover = true"
                @mouseleave="suggestionHover = false"
                id="suggestion"
            >
                <b-list-group
                >
                    <b-list-group-item
                        v-for="(card, key) in suggestedCards"
                        :key="card.slug"
                        :ref="card.slug"
                        class="py-2 px-3 "
                        :class="{'active' : selectedIndex === key}"
                        @click="navigateToCard(card.slug)"
                        button
                    >
                        {{ card.name }}
                    </b-list-group-item>
                </b-list-group>
            </div>
        </slide-y-up-transition>
	</div>
</template>

<style lang="scss">
    @import '/public/css/vars.scss';
    .card-search {
        position: relative;

        &__input {
            &--has-suggestion {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &__suggestion {
            position: absolute;
            z-index: 1;
            width: 100%;

            max-height: 205px;
            overflow-y: scroll;

            scrollbar-width: thin;
            scrollbar-color: $primary-blue;
            scroll-behavior: smooth;

            border-radius: 3px;
            border-bottom: 1px solid #444;
            .list-group-item:first-child {
                border-top: none;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
            .list-group-item:last-child {
                border-bottom-right-radius: 0px;
            }
            // .list-group-item-action:hover {
            //     background-color: darken($primary-blue, 20%);
            // }
        }
        

        &__suggestion::-webkit-scrollbar {
            width: 4px;
        }

        &__suggestion::-webkit-scrollbar-track {
            background-color: #4b4b4b;
            border-bottom-right-radius: 3px;
        }

        &__suggestion::-webkit-scrollbar-thumb {
            border-bottom-right-radius: 3px;
            border: 1px solid darken($primary-blue, 20%);
            background-clip: content-box;
            // background-color: $primary-blue;
            background-color: rgb(183, 183, 183);
        }
    }
</style>