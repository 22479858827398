<script>
import Search from "@/components/Search.vue";

export default {
	name: "Navigation",
	components: {
		Search,
	},
	computed: {
		showSearch() {
			return this.$route.name !== "Home";
			// return this.$route.name;
		},
		totalPrintCards () {
			return this.$store.getters.totalPrintCards
		}
	},
};
</script>

<template>
	<div>
		<b-navbar toggleable="lg" type="dark">
			<b-navbar-brand :to="{ name: 'Home' }">
                <img
                    src="/images/logo.png"
                    class="align-top"
                    width="110px"
                    alt="Index"
                >
            </b-navbar-brand>

			<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

			<b-collapse id="nav-collapse" is-nav>
				<b-navbar-nav>
					<b-nav-item :to="{ name: 'SearchResult' }">Cards</b-nav-item>
					<b-nav-item :to="{ name: 'AdvancedSearch' }">Advanced Search</b-nav-item>
					<b-nav-item :to="{ name: 'Printer' }">
						Print & Play
						<b-badge
							v-if="totalPrintCards > 0"
							pill
							variant="primary"
							style="vertical-align: middle"
							:content="`${totalPrintCards} card${totalPrintCards > 1 ? 's' : ''} ready to print`"
							v-tippy="{ 
								placement: 'bottom',
								arrow: true
							}"
						>
							{{ totalPrintCards }}
						</b-badge>
					</b-nav-item>
					<b-nav-item :to="{ name: 'API' }">API</b-nav-item>
				</b-navbar-nav>


				<b-navbar-nav class="ml-auto">
					<Search
						v-if="showSearch"
					/>
				</b-navbar-nav>

					<!-- <b-nav-item-dropdown text="Lang" right>
            <b-dropdown-item href="#">EN</b-dropdown-item>
            <b-dropdown-item href="#">ES</b-dropdown-item>
            <b-dropdown-item href="#">RU</b-dropdown-item>
            <b-dropdown-item href="#">FA</b-dropdown-item>
          </b-nav-item-dropdown> -->

					<!-- <b-nav-item-dropdown right>
            <template #button-content>
              <em>User</em>
            </template>
            <b-dropdown-item href="#">Profile</b-dropdown-item>
            <b-dropdown-item href="#">Sign Out</b-dropdown-item>
          </b-nav-item-dropdown> -->
				<!-- </b-navbar-nav> -->
			</b-collapse>
		</b-navbar>
	</div>
</template>


<style scoped>
</style>
