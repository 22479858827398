<script>
// @ is an alias to /src
import Search from "@/components/Search.vue";
import Catalog from "@/components/Catalog.vue";
import ChangeLog from "@/components/ChangeLog.vue";


export default {
	name: "Home",
	components: {
		Search,
		Catalog,
		ChangeLog
	},
	data() {
		return {
            changelogLoaded: false
        };
	},
};
</script>

<template>
	<div class="page">
		<b-container
            v-show="changelogLoaded"
            class="page__home"
        >
			<b-row>
				<b-col
                    class="animate__animated animate__fadeIn"
                >
                    <b-jumbotron
                        border-variant="secondary"
                        class="main-jumbrotron effect--backlight background-slideshow pt-4 pb-4"
                    >
                        <b-row align-h="center">
                            <b-col cols="12" md="8" lg="6" class="text-center" >
                                <img
                                    src="images/full-logo.png"
                                    class="mb-4"
                                    width="250px"
                                    alt="Grand Archive TCG Explorer"
                                />
                                <Search />
                            </b-col>
                        </b-row>
                        <b-row align-h="center">
                            <b-col cols="12" md="8" lg="6" class="mt-5">
                                <ChangeLog
                                    header="What's New"
                                    :limit="2"
                                    has-more
                                    @load-started="changelogLoaded = false"
                                    @loaded="changelogLoaded = true"
                                />
                            </b-col>
                        </b-row>

                        <div class="background-slideshow__slides">
                            <figure />
                            <figure />
                            <figure />
                            <figure />
                        </div>
                        <!-- for preloading slideshow image -->
                        <div class="d-none">
                            <img src="/images/slides/1.jpg" />
                            <img src="/images/slides/2.jpg" />
                            <img src="/images/slides/3.jpg" />
                            <img src="/images/slides/4.jpg" />
                        </div>
                    </b-jumbotron>
				</b-col>
			</b-row>
			<b-row class="mb-4">
				<b-col>
					<Catalog random refresh-random-button />
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>


<style lang="scss">
// @keyframes zooming {
// 	0% {
// 		background-size: 1090px;
// 	}
// 	50% {
// 		background-size: 1300px;
// 	}
// 	100% {
// 		background-size: 1090px;
// 	}
// }
// @keyframes slideChange {
// 	0% {
// 		background-image: url(/images/slides/1.jpg);
// 	}
// 	17% {
// 		background-image: url(/images/slides/1.jpg);
// 	}

// 	20% {
// 		background-image: url(/images/slides/2.jpg);
// 	}
// 	37% {
// 		background-image: url(/images/slides/2.jpg);
// 	}

// 	40% {
// 		background-image: url(/images/slides/3.jpg);
// 	}
// 	79% {
// 		background-image: url(/images/slides/3.jpg);
// 	}

// 	80% {
// 		background-image: url(/images/slides/4.jpg);
// 	}
// 	97% {
// 		background-image: url(/images/slides/4.jpg);
// 	}
// 	100% {
// 		background-image: url(/images/slides/1.jpg);
// 	}
// }
// .effect--background {
// 	background-image: url(/images/slides/1.jpg);
// 	background-repeat: no-repeat;
// 	background-position: center center;
// 	background-size: 1090px;
// 	// animation: zooming 10s linear infinite, slideChange 10s linear infinite;
// 	animation: slideChange 10s linear infinite;
// }


// reference https://tympanus.net/codrops/2012/01/02/fullscreen-background-image-slideshow-with-css3/
@keyframes imageAnimation {
	0% {
		animation-timing-function: ease-in;
		opacity: 0;
        transform: scale(1);
	}
	8% {
		animation-timing-function: ease-out;
		opacity: 1;
	}
	// 17% {
	// 	opacity: 1;
    //     transform: scale(1.15);
	// }
	30% {
		opacity: 1;
        transform: scale(1.15);
	}
    45% {
        opacity: 0;
        transform: scale(1.22);
    }
	100% {
		opacity: 0;
        transform: scale(1);
	}
}

.main-jumbrotron {
    background-color: #111;
}
@media (min-width: 768px) {
     .background-slideshow {
    
        background-color: transparent;
        position: relative;

        &__slides {
            z-index: -1;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            overflow: hidden;
            background-color:#111;

            > figure {
                animation: imageAnimation 28s linear infinite 0s;
                backface-visibility: hidden;
                background-size: cover;
                background-position: center center;
                color: transparent;
                height: 100%;
                left: 0px;
                position: absolute;
                top: 0px;
                width: 100%;
                opacity: 0;
            }
            > figure:nth-child(1) {
                background-image: url(/images/slides/1.jpg);
            }
            > figure:nth-child(2) {
                animation-delay: 7s;
                background-image: url(/images/slides/2.jpg);
            }
            > figure:nth-child(3) {
                animation-delay: 14s;
                background-image: url(/images/slides/3.jpg);
            }
            > figure:nth-child(4) {
                animation-delay: 21s;
                background-image: url(/images/slides/4.jpg);
            }
        }
    }
}




</style>
