export default {
    protocol: 'https',
    url:{
        api: 'https://api.gatcg.com',
        image: 'https://ga-index-public.s3.us-west-2.amazonaws.com'
    },
    gtag:{
        id: 'G-P06DM95KYD',
        appName: 'GA Index'
    },
    disqus: {
        shortname: 'gatcg'
    },

    enable: {
        disqus: true,
        gtag: true
    }
}
  