import http from "../http-common";
import TextService from "@/services/text"

class DataService {
    searchCards(query) {
        // const queryString = new URLSearchParams({ name: 'lorraine', stats: 'life>10' }).toString()
        // const queryString = new URLSearchParams(query).toString()
        const queryString = TextService.convertToURLParams(query)
        return http.get(`/cards/search?${queryString}`);
    }

    searchAutocomplete(name) {
        return http.get(`/cards/autocomplete?name=${name}`);
    }

    getCard(slug) {
        return http.get(`/cards/${slug}`);
    }
    getEdition(editionSlug) {
        return http.get(`/cards/edition/${editionSlug}`);
    }
    getRandomCards(amount) {
        return http.get(`/cards/random?amount=${amount}`);
    }

    getChangelog({ limit }) {
        return http.get(`/changelog/list?limit=${limit}`);
    }

    getSearchOptions() {
        return http.get(`/option/search`);
        //hardcoded for now. will be in API later
        // const option = {
        //     type: [
        //         { text: 'Action', value: 'ACTION'},
        //         { text: 'Ally', value: 'ALLY'},
        //         { text: 'Attack', value: 'ATTACK'},
        //         { text: 'Champion', value: 'CHAMPION'},
        //         { text: 'Domain', value: 'DOMAIN'},
        //         { text: 'Item', value: 'ITEM'},
        //         { text: 'Regalia', value: 'REGALIA'},
        //         { text: 'Weapon', value: 'WEAPON'},
        //     ],
        //     // select distinct unnest(subtypes) as uniqueSubtypes from cards order by uniqueSubtypes
        //     subtype: [
        //         { text: "Accessory", value: "ACCESSORY"},
        //         { text: "Animal", value: "ANIMAL"},
        //         { text: "Artifact", value: "ARTIFACT"},
        //         { text: "Assassin", value: "ASSASSIN"},
        //         { text: "Bauble", value: "BAUBLE"},
        //         { text: "Beast", value: "BEAST"},
        //         { text: "Bird", value: "BIRD"},
        //         { text: "Boar", value: "BOAR"},
        //         { text: "Book", value: "BOOK"},
        //         { text: "Cleric", value: "CLERIC"},
        //         { text: "Craft", value: "CRAFT"},
        //         { text: "Crystal", value: "CRYSTAL"},
        //         { text: "Fairy", value: "FAIRY"},
        //         { text: "Guardian", value: "GUARDIAN"},
        //         { text: "Horse", value: "HORSE"},
        //         { text: "Human", value: "HUMAN"},
        //         { text: "Mage", value: "MAGE"},
        //         { text: "Ranger", value: "RANGER"},
        //         { text: "Reaction", value: "REACTION"},
        //         { text: "Scepter", value: "SCEPTER"},
        //         { text: "Skill", value: "SKILL"},
        //         { text: "Spell", value: "SPELL"},
        //         { text: "Spirit", value: "SPIRIT"},
        //         { text: "Squirrel", value: "SQUIRREL"},
        //         { text: "Sword", value: "SWORD"},
        //         { text: "Tamer", value: "TAMER"},
        //         { text: "Warrior", value: "WARRIOR"},
        //         { text: "Wolf", value: "WOLF"}
        //     ],
        //     class: [
        //         { text: 'Assasin', value: 'ASSASIN'},
        //         { text: 'Cleric', value: 'CLERIC'},
        //         { text: 'Guardian', value: 'GUARDIAN'},
        //         { text: 'Mage', value: 'MAGE'},
        //         { text: 'Ranger', value: 'RANGER'},
        //         { text: 'Tamer', value: 'TAMER'},
        //         { text: 'Warrior', value: 'WARRIOR'},
        //     ],
        //     element: [
        //         { text: 'Arcane', value: 'ARCANE' },
        //         { text: 'Crux', value: 'CRUX' },
        //         { text: 'Fire', value: 'FIRE' },
        //         { text: 'Norm', value: 'NORM' },
        //         { text: 'Wind', value: 'WIND' },
        //     ],
        //     rarity: [
        //         { text: 'Common', value: '1' },
        //         { text: 'Uncommon', value: '2' },
        //         { text: 'Rare', value: '3' },
        //         { text: 'Super Rare', value: '4' },
        //         { text: 'Ultra Rare', value: '5' },
        //         { text: 'Collector\'s Rare', value: '6' },
        //         { text: 'Promotional Rare', value: '7' },
        //     ],
        //     stat: [
        //         { text: 'Memory Cost', value: 'cost_memory'},
        //         { text: 'Reserve Cost', value: 'cost_reserve'},
        //         { text: 'Level', value: 'level'},
        //         { text: 'Attack', value: 'attack'},
        //         { text: 'Life', value: 'life'},
        //         { text: 'Durability', value: 'durability'},
        //     ],
        //     set: [
        //         { text: 'Dawn of Ashes Prelude', value: 'DOAp', lang: 'EN'},
        //         { text: 'LGS Demo 2022', value: 'DEMO22', lang: 'EN'}
        //     ],
        //     statOperator: [
        //         { text: 'equal to', symbol: '=', value: '='},
        //         { text: 'less than', symbol: '<', value: '<'},
        //         { text: 'greater than', symbol: '>', value: '>'},
        //         { text: 'less than or equal to', symbol: '≤', value: '<='},
        //         { text: 'greater than or equal to', symbol: '≥', value: '>='},
        //     ]
        // }

        // return new Promise((resolve) => {
        //     resolve({ data: option });
        // });
    }
}

export default new DataService();