import Vue from 'vue'
import config from "@/config";


import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import "../public/css/vars.scss";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import 'animate.css';

import "../public/css/bootstrap.min.css"
import "../public/css/global.scss";
import "../public/css/effect.scss";
import "../public/css/font.scss";

import VueApexCharts from 'vue-apexcharts'
import VueTippy, { TippyComponent } from "vue-tippy";
import VueGtag from "vue-gtag";
import VueScrollactive from 'vue-scrollactive';
import VueCookies from 'vue-cookies'

import App from './App.vue'
import router from './router'
import store from './store'

import DataService from "@/services/data";

Vue.config.productionTip = false



Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueTippy)
Vue.use(VueScrollactive)
Vue.use(VueApexCharts)
Vue.use(VueCookies, { expires: '30d'})
Vue.component("tippy", TippyComponent);
Vue.component('apexchart', VueApexCharts);
Vue.prototype.$eventHub = new Vue();


if (config.enable.gtag) {
    Vue.use(VueGtag, {
        config: { id: config.gtag.id },
        appName: config.gtag.appName,
        pageTrackerScreenviewEnabled: true
    }, router);
}


DataService.getSearchOptions()
    .then((response) => {
        Vue.prototype.$searchOptions = response.data
        Vue.prototype.$eventHub.$emit('apiloadcomplete')
    })
    .catch((e) => {
        console.log(e);
    });


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
