import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})

Vue.use(Vuex)

Object.defineProperty(Array.prototype, 'getCard', {
    value: function(slug) { return this.filter(p => p.slug === slug)[0] }
});
function refreshArray(array) {
    array.push({})
    array.splice(array.length - 1, 1)
}


export default new Vuex.Store({
    state: {
        searchResultPage: {
            viewMode: 'gallery', // catalog search result view mode 'list', 'gallery'
        },
        printCards: []
    },
    mutations: {
        setSearchResultViewMode(state, mode) {
            state.searchResultPage.viewMode = mode
        },
        setPrintCards(state, printCards) {
            state.printCards = printCards
        },
        addPrintCard(state, {card, addAmount = 1}) {
            // check if the card exists already
            // const existingCard = state.printCards.filter(p => p.slug === card.slug)[0]
            const existingCard = state.printCards.getCard(card.slug)
            if ( existingCard ) {
                existingCard.printAmount = existingCard.printAmount ? existingCard.printAmount + addAmount : addAmount;

                if (existingCard.printAmount === 0) {
                    const removeIndex = state.printCards.map(p => p.slug).indexOf(card.slug)
                    state.splice(removeIndex, 1)
                    return
                }
               
                // trigger reactivity by adding an empty card object and remove it
                // state.printCards.push({})
                // state.printCards.splice(state.printCards.length - 1, 1)
                refreshArray(state.printCards)

                return;
            }
 
            card.printAmount = addAmount
            state.printCards.push(card)
        },
        setPrintCardAmount(state, {card, amount}) {
            const existingCard = state.printCards.getCard(card.slug)

            if (existingCard) {
                existingCard.printAmount = amount

                if (existingCard.printAmount === 0) {
                    const removeIndex = state.printCards.map(p => p.slug).indexOf(card.slug)
                    state.printCards.splice(removeIndex, 1)
                    return
                }
                
                // trigger reactivity by adding an empty card object and remove it
                // state.printCards.push({})
                // state.printCards.splice(state.printCards.length - 1, 1)
                refreshArray(state.printCards)

                return;
            }

            card.printAmount = amount
            state.printCards.push(card)
        },
        refreshPrintCards(state) {
            refreshArray(state.printCards)
        }
    },
    getters: {
        // searchResultPage: state => state.searchResultPage,
        // printCards: state => state.printCards
        totalPrintCards: function (state) {
            return state.printCards.map(c => c.printAmount).reduce((partialSum, a) => partialSum + a, 0);
        },
        getPrintAmount: state => (cardSlug) => {
            const existingCard = state.printCards.getCard(cardSlug)
            if(!existingCard) return 0
            
            return existingCard.printAmount
        }
    },
    actions: {
    },
    modules: {
    },
    plugins: [vuexLocal.plugin]
})
