
<script>
import DataService from "@/services/data";
import TextService from "@/services/text";

import DynamicContent from "@/components/DynamicContent.vue";

export default {
	name: "ChangeLog",
    components: {
		DynamicContent,
	},
	props: {
        hasMore: Boolean,
		header: {
            type: String,
        },
        limit: {
            type: Number,
            default: 0
        },
	},
    data() {
		return {
            logs: [],
            loading: false,
		};
	},
    methods: {
        getData() {
            const params = {};
            
            if (this.limit > 0) {
                params['limit'] = this.limit
            }
			DataService.getChangelog(params)
				.then((response) => {
					this.logs = response.data;
                    this.$emit('loaded')
                    this.loading = false;
				})
				.catch((e) => {
					console.log(e);
				});
		},
        toMonthDateYear: TextService.toMonthDateYear
    },
    mounted() {
        this.$emit('load-started')
        this.loading = true
		this.getData();
	},
};



</script>

<template>
	<div 
        v-if="logs.length"
        class="changelog animate__animated animate__fadeIn"
    >
        <div
            v-if="header"
            class="changelog__header"
        >
            {{ header }}
        </div>
		<div
            class="changelog__updates"
        >
            <div
                v-for="(log, index) in logs"
                :key="index"
                class="update"
            >
                <div class="update__date">
                    {{ toMonthDateYear(log.date) }}
                </div>
                <div class="update__content">
                    <DynamicContent :html="log.content" />
                </div>
            </div>
            

            <!-- <div class="update">
                <div class="update__date">
                    4/21/2021
                </div>
                <div class="update__content">
                    Added new set <a href="http://localhost:8080/cards?set=DOAp">Dawn of Ashes Prelude</a>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </div>
            </div>
            <div class="update">
                <div class="update__date">
                    4/21/2021
                </div>
                <div class="update__content">
                    Added new set <a href="http://localhost:8080/cards?set=DOAp">Dawn of Ashes Prelude</a>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </div>
            </div> -->
        </div>
        <div class="text-center">
            <b-button
                v-if="hasMore"
                :to="{ name: 'News' }"
            >
                <b-icon
                    icon="three-dots"
                    scale="0.9"
                    shift-v="-1"
                    class="mr-1"
                ></b-icon>
                See more
            </b-button>
        </div>
	</div>
</template>

<style lang="scss" scoped>
.changelog {
    padding: 5px;
    &__header {
        font-size: 1.1rem;
        font-weight: 300;
    }
	&__updates {
        margin-top: 5px;
	}
    
}
.update {
    display: flex;
    margin-bottom: 15px;
    &__date {
        flex-basis: 85px;
        padding-right: 15px;
    }
    &__content {
        flex-basis: calc(100% - 85px);
        &__title {
            text-transform: uppercase;
        }
    }
}
</style>

