export default {
    debug: true,
    protocol: 'http',
    url: {
        api: process.env.VUE_APP_API_URL,
        image: process.env.VUE_APP_IMAGE_BUCKET,
    },
    gtag: {
        id: 'G-P06DM95KYD',
        appName: 'GA Index'
    },
    disqus: {
        shortname: 'gatcg'
    },

    enable: {
        disqus: false,
        gtag: false
    }
}