import axios from "axios";
import config from "@/config";

const apiConfig = {
    baseURL: config.url.api,
    headers: {
        "Content-type": "application/json"
    }
}
if (config.key?.api) {
    apiConfig.headers["x-api-key"] = config.key.api
}

export default axios.create(apiConfig);