<script>
import {debounce} from 'lodash';

import DataService from "@/services/data";
import TextService from "@/services/text";

import CardImage from "@/components/CardImage.vue";
import CostIcon from "@/components/CostIcon.vue";
import TypeIcon from "@/components/TypeIcon.vue";
import ElementIcon from "@/components/ElementIcon.vue";

export default {
	name: "Catalog",
	components: {
        CardImage,
		CostIcon,
		TypeIcon,
		ElementIcon,
    },
	props: {
		listMode: Boolean,
		random: Boolean,
        refreshRandomButton: Boolean,
		debug: Boolean,
		data: {
			type: Array,
			default: null
		},

	},
	data() {
		return {
			cards: null,
            mountedCardImages: [], // array to store images that have been mounted with a delay
            hoverEditionSlug: null,
            hoverEditionName: null,
            cardImageInitial: true,
		};
	},
	methods: {
		getRandomData() {
			DataService.getRandomCards(10)
				.then((response) => {
					this.cards = response.data;
				})
				.catch((e) => {
					console.log(e);
				});
		},
		goToCard(slug) {
			this.$router.push({ name: 'Card', params: { slug }})
		},
		goToEdition(slug) {
			this.$router.push({ name: 'Edition', params: { editionSlug:slug }})
		},
		hasResultEdition(card) {
			return card.result_editions && card.result_editions[0] && card.result_editions[0].slug
		},
        getCardImageSlug(card) {
            if (card.result_editions && card.result_editions.length) {
                return card.result_editions[0].slug
            }
            if (card.editions.length) {
                return card.editions[0].slug
            }
            return ''
        },
        onCardImageMounted(card) {
            
            //testing here jackchalat
            console.log('Catalog onCardImageMounted')
            this.cardImageInitial = false;

            this.mountedCardImages.push(card.slug)
        },
        onCardImageBeforeDestroy() {
            this.mountedCardImages = []
        },
        isCardMounted(card) {
            return this.mountedCardImages.indexOf(card.slug) > -1
        },
        onRowMouseEnter: debounce( function(card) {
            this.hoverEditionSlug = this.getCardImageSlug(card);
            this.hoverEditionName = card.name;
            if (!this.$refs.cardTooltip.tippy().state.isVisible) this.$refs.cardTooltip.tippy().show()
        }, 0),
        onTableMouseLeave: debounce( function() {
            this.$refs.cardTooltip.tippy().hide()
        }, 0),
		parseType: TextService.parseType,
		capitalizeFirstChar: TextService.capitalizeFirstChar,
		getRarityString: TextService.getRarityString,
		getCardSubtitle: TextService.getCardSubtitle
	},
	mounted() {
		if (this.random) this.getRandomData();
		if (this.data) this.cards = this.data;
	},
    updated() {
    }
};
</script>

<template>
	<div
		v-if="cards"
		class="catalog"
        :class="{'catalog-list' : listMode, 'catalog-gallery': !listMode}"
	>
		<!-- Gallery mode -->
		<b-row
			v-if="!listMode"
			cols-md="4"
			cols-lg="5"
		>
			<b-col
				v-for="(card) in cards"
				:key="card.slug"
				cols="6"
				sm="4"
				md="3"
				:lg="false"
				class="py-2 px-1"
			>
				<router-link
					:to="hasResultEdition(card) ? { name: 'Edition', params: { editionSlug: card.result_editions[0].slug }} : { name: 'Card', params: { slug: card.slug }}"
                    :class="{'hover__aurora' : isCardMounted(card) }"
				>
                    <CardImage
                        :slug="getCardImageSlug(card)"
                        :alt="card.name"
                        :initial="cardImageInitial"
                        class="animate__animated animate__faster animate__fadeInUp"
                        @card-image-mounted-delay="onCardImageMounted(card)"
                        @card-image-before-destroy="onCardImageBeforeDestroy"
                    />
					<div
						v-if="debug"
						class="card_debug"
					>
						{{ hasResultEdition(card) ? card.result_editions[0].slug : card.slug }}
					</div>
				</router-link>
			</b-col>
		</b-row>

		<!-- List mode -->
		<b-row
			v-if="listMode"
		>
			<b-col>
                <!-- Card Image toolips -->
				<tippy
                    ref="cardTooltip"
                    :animate-fill="false"
                    :flip-on-update="true"
                    animation="none"
                    theme="no-bg"
                    follow-cursor
                    placement="right-end"
                    max-width="300px"
                >
                    <CardImage
                        :key="hoverEditionSlug"
                        v-if="hoverEditionSlug"
                        :slug="hoverEditionSlug"
                        :alt="hoverEditionName"
                    />
                </tippy>

				<b-table-simple
					class="table-cards"
					hover
				>
					<b-thead head-variant="dark">
						<b-tr class="d-md-table-row d-sm-none d-none">
							<b-th>Set</b-th>
							<b-th colspan="1">№</b-th>
							<b-th>Cost</b-th>
							<b-th>Element</b-th>
							<b-th>Name</b-th>
							<b-th>Types</b-th>
							<b-th colspan="1">Rarity</b-th>
						</b-tr>
					</b-thead>
					<b-tbody
                        @mouseleave="onTableMouseLeave"
                    >
						<b-tr
							v-for="(card, index) in cards"
							:key="index"
                            :name="card.slug"
                            class="animate__fadeIn animate__animated animate__faster"
							@click="hasResultEdition(card) ? goToEdition(card.result_editions[0].slug) : goToCard(card.slug)"
                            @mouseenter="onRowMouseEnter(card)"
						>
							<!-- large device columns -->
							<b-td class="d-md-table-cell d-sm-none d-none">
								{{ card.result_editions[0] && card.result_editions[0].set ? card.result_editions[0].set.prefix : '' }}
							</b-td>
							<b-td class="d-md-table-cell d-sm-none d-none">
								{{ card.result_editions[0] ? card.result_editions[0].collector_number : '' }}
							</b-td>
							<b-td class="d-md-table-cell d-sm-none d-none">
								<CostIcon
									v-if="card.cost_memory !== null"
									:cost="card.cost_memory"
									type="memory"
								/>
								<CostIcon
									v-if="card.cost_reserve !== null"
									:cost="card.cost_reserve"
									type="reserve"
								/>
							</b-td>
							<b-td class="d-md-table-cell d-sm-none d-none">
								<ElementIcon
									:element="card.element.toLowerCase()"
									:title="capitalizeFirstChar(card.element)"
								/>
							</b-td>
							<b-td class="d-md-table-cell d-sm-none d-none">
								{{ card.name }}
							</b-td>
							<b-td class="d-md-table-cell d-sm-none d-none">
								<TypeIcon
									:types="card.types"
									:title="parseType(card.types)"
								/>{{ getCardSubtitle({
											types: card.types,
											classes: card.classes,
											subtypes: card.subtypes
										}) 
									}}
							</b-td>
							<b-td class="d-md-table-cell d-sm-none d-none">
								<span
									v-if="card.result_editions[0]"
									class="rarity__text"
									:class="[`rarity__text--${card.result_editions[0].rarity}`]"
									:title="getRarityString(card.result_editions[0].rarity)"
								>
									{{ getRarityString(card.result_editions[0].rarity, true) }}
								</span>
							</b-td>

							<!-- medium and small device columns -->
							<b-td class="d-md-none d-lg-none">
								<b-row>
									<b-col
										cols="4"
									>
										<div>
											{{ card.result_editions[0] && card.result_editions[0].set ? card.result_editions[0].set.prefix : '' }}
											{{ card.result_editions[0] ? card.result_editions[0].collector_number : '' }}
											<span
												v-if="card.result_editions[0]"
												class="rarity__text"
												:class="[`rarity__text--${card.result_editions[0].rarity}`]"
												:title="getRarityString(card.result_editions[0].rarity)"
											>
												({{ getRarityString(card.result_editions[0].rarity, true) }})
											</span>
										</div>
									</b-col>
									<b-col
										class="pl-0"
										cols="8"
									>
										<b-row>
											<b-col
												class="text-center"
												cols="3"
											>
												<div>
													<CostIcon
														v-if="card.cost_memory !== null"
														:cost="card.cost_memory"
														type="memory"
														small
													/>
													<CostIcon
														v-if="card.cost_reserve !== null"
														:cost="card.cost_reserve"
														type="reserve"
														small
													/>
												</div>
												<div class="mt-2">
													<ElementIcon
														:element="card.element.toLowerCase()"
														:title="capitalizeFirstChar(card.element)"
														small
													/>
												</div>
											</b-col>
											<b-col
												class=""
												cols="9"
											>
												<span class="card-title">{{ card.name }}</span>
												<br>
												<div class="mt-3">
													<TypeIcon
														:types="card.types"
														:title="parseType(card.types)"
													/>
													{{ getCardSubtitle({
															types: card.types,
															classes: card.classes,
															subtypes: card.subtypes
														}) 
													}}
												</div>
											</b-col>
										</b-row>
									</b-col>

								</b-row>
								
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>


			</b-col>
		</b-row>

        <b-row
            v-if="this.refreshRandomButton"
            class="mt-2"
        >
            <b-col class="text-center">
				<b-button
					class="mr-3"
					:to="{ name: 'SearchResult'}"
                >
                    <b-icon
                        icon="collection"
                        scale="0.85"
						rotate="90"
                        class="mr-1"
                    />
                    View All Cards
                </b-button>
                <b-button
                    @click="getRandomData"
                >
                    <b-icon
                        icon="dice-3-fill"
                        scale="0.65"
                        rotate="45"
                        shift-v="-1"
                        class="mr-1"
                    />
                    Reroll
                </b-button>
            </b-col>
        </b-row>
		
	</div>
</template>


<style lang="scss" scoped>
    // .catalog {
    //     &-list {
    //     }
    // }

	.card_debug {
		position: absolute;
		text-align:center;
		width: calc(100% - 40px);
		top: 25px;
		left: 0;
		margin: 0 20px;
		padding: 5px;
		background: rgba(0, 0, 0, 0.75);
		border-radius: 5px;
		font-size: 0.9rem;
		color: white;
	}

	.table-cards {
		tbody tr {
			cursor:pointer;
		}
		tbody td {
			vertical-align: middle;
		}
	}
	.card-cursor {
		max-width: 100%;
	}
	.card-title {
		font-size: 1.2em;
	}
</style>