<script>
export default {
	name: "Footer",
    computed: {
        env () {
            if (process.env.NODE_ENV !== 'production') return process.env.NODE_ENV

            return ''
        }
    }
};
</script>

<template>
	<div
        class="footer"
    >
        <b-container>
            <b-row class="px-2 pb-3">
                <b-col
                    cols="12"
                    md="6"
                    class="text-md-left"
                >
                    Index Beta v0.1 {{ env }}
                </b-col>
                <b-col
                    cols="12"
                    md="6"
                    class="text-md-right"
                >
                    © 2022 Weebs of the Shore LLC
                </b-col>
            </b-row>
        </b-container>
	</div>
</template>


<style lang="scss" scoped>
    .footer {
        margin-top: 30px;
        padding: 15px 0 15px;
        color: #777;

        box-shadow: 0px 1px 28px 11px rgba(0,0,0,0.25);
        border-top: 1px solid #2e2e2e;
        background-color: #1c1c1c;
        
        height: 55px;
    }
</style>
