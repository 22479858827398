import config from "@/config";
const BASE_URL = config.url.image;

class ImageService {
    getBaseURL() {
        return BASE_URL
    }
    getCardUrl(slug) {
        return `${BASE_URL}/cards/${slug}.jpg`
    }
    getBackSide() {
        return `${BASE_URL}/back.jpg`
    }
    get404() {
        return `${BASE_URL}/back.jpg`
    }
}

export default new ImageService();