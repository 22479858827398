<script>
import ImageService from "@/services/image";

export default {
    name: 'CardImage',
    props: {
        slug: {
            type: String,
            required: true
        },
        alt: {
            type: String,
            required: true
        },
        initial: { // set to true if CardImage is initially created in the parent component. set to false if CardImage is dynamically create
            type: Boolean,
            default: false
        },
        loadCompleteAnimation: Boolean,
    },
    data() {
		return {
            cardImageNotFound: false,
            cardImageLoadCall: 0, // must be 2 to mean theat b-img-lazy is loaded
            cardImageLoaded: false,
            loading: false,
		};
    },
    methods: {
        onCardImageLoad() {

            this.cardImageLoadCall ++
            if (this.cardImageLoadCall === 1) {
                this.loading = true
            }


            if (this.cardImageLoadCall === 2) {
                this.cardImageLoaded = true
                this.loading = false
                this.$emit('card-image-loaded')
            }
        },
        onCardImageError() {
            this.cardImageNotFound = true
        },
		getCardUrl: ImageService.getCardUrl,
        getBackSide: ImageService.getBackSide,
        get404: ImageService.get404
	},
    computed: {
        imageUrl() {
            if (this.cardImageNotFound) {
                return this.get404()
            }
            if (this.slug === '') {
                return this.get404()
            }
            return this.getCardUrl(this.slug)
        },
        backCardUrl() {
            return this.getBackSide()
        }
    },
    mounted() {
        setTimeout(() => {
            this.$emit('card-image-mounted-delay')
        }, 500)

        // disregard this.initial unless it's Firefox or iOS
        if (
            (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) |
            (/iPad|iPhone|iPod/.test(navigator.userAgent))
            )
        {
            if (this.initial) this.onCardImageLoad()
        }
        
    },
    updated() {
        
    },
    beforeDestroy() {
        this.$emit('card-image-before-destroy')
    }
}
</script>

<template>
    <div
        class="card__image__wrapper"
        :class="{' animate__animated animate__faster' : loadCompleteAnimation ,'animate__pulse' : !loading && loadCompleteAnimation }"
    >
        <!-- debug slug name -->
        <!-- <div class="debug-slug">
            {{ slug }}
        </div> -->

        <div
            v-if="loading"
            class="effect--loader-circle card__image__spinner"
        />

        <b-img
            fluid-grow
            width="400"
            height="560"
            blank
            class="card__image__cover"
            :class="{'card__image__cover--removed' : !loading }"
        />

        <b-img-lazy
            fluid-grow
            :src="imageUrl"
            :alt="alt"
            class="card__image"
            width="400"
            blank-width="400"
            height="560"
            blank-height="560"
            blank
            blank-color="#111"
            @error.native="onCardImageError"
            @load.native="onCardImageLoad"
        />

        <!-- <b-img
            :src="backCardUrl"
            fluid-grow
            width="400"
            height="560"
            blank
            blank-color="#111"
            class="card__image__back"
        /> -->
        
    </div>
</template>


<style scoped lang="scss">
    @import '/public/css/vars.scss';
    /* Card Image */
    .card__image__wrapper {
        position: relative;
        .debug-slug {
            position: absolute;
            text-align: center;
            background-color: rgba(0,0,0, 0.8);
            padding: 10px;
            width: 100%;
        }
    }
    .card__image {
        border-radius: 4.75% / 3.5%;
        box-shadow: 2px 4px 15px -1px rgba(0,0,0,0.35);

        &__cover {
            border-radius: 4.75% / 3.5%;
            transition: background-color 0.3s linear, border 0.3s linear;
            background-color: #181818;
            border: 1px solid #0c0c0c;
            position: absolute;
            left: 0;
            right: 0;
        }
        &__cover--removed {
            pointer-events: none;
            background-color: transparent;
            border: 1px solid transparent;
        }

        &__spinner {
            z-index: 1;
            color: $primary-blue;
            position: absolute;
            top: calc(50% - 16px);
            left: calc(50% - 16px);
        }
    }
    .card__image__back {
        border-radius: 4.75% / 3.5%;

        position: absolute;
        left: 0;
        z-index: -1;
    }

</style>
